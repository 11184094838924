@import 'assets/stylesheets/scss/tokens';
@import '../Button.module.scss';

@mixin primaryButtonIconColor {
  @include iconSize();
  &.primary {
    &.default,
    &.danger {
      &,
      &:hover,
      &:active,
      &:focus-visible {
        svg g[fill],
        svg path[fill],
        svg polygon[fill] {
          fill: $theme-white;
        }
      }

      &:disabled {
        svg g[fill],
        svg path[fill],
        svg polygon[fill] {
          opacity: 0.5;
        }
      }
    }
  }
}

.primary {
  @extend .healthieButton;

  color: $theme-white !important;
  
  &.default {
    background-color: $theme-accent-primary;
  }
  &.danger {
    background-color: $theme-notice-bold-warning;
  }
 
  &:not(:disabled):not(.loading):hover {
      &.default {
        background-color: $theme-accent-primary-hover;
      }
      &.danger {
        background-color: $theme-notice-bold-warning-hover;
      }
  }

  &:not(:disabled):not(.loading):active {
      &.default {
        background-color: $theme-accent-primary-hover;
        border: 1px solid $theme-accent-primary-hover;
      }
      &.danger {
        background-color: $theme-notice-bold-warning-hover;
        border: 1px solid $theme-notice-bold-warning-hover;
      }
    }
}

.buttonWithIcon {
  @include primaryButtonIconColor();

  &.primary {
    &:disabled {
      &:hover,
      &:active,
      &:focus-visible {
        cursor: default;
      }
    }
  }
}

.buttonWithText {
  &:disabled,
  &.loading {
    &.primary {
      &.default,
      &.danger {
        cursor: default;
      }
    }
  }
}

.buttonWithTextAndIcon {
  @extend .buttonWithText;
  @include primaryButtonIconColor();
}
