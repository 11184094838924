@import 'src/assets/stylesheets/scss/tokens';

.error {
  color: $theme-notice-bold-warning
}

.orderLabelContainer {
  display: flex;
  align-content: center;
}

.labOrderViewTooltip {
  width: 300px;
}