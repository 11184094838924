@import 'src/assets/stylesheets/scss/utilities/MediaQueries/mediaQueryMixins';
@import 'src/assets/stylesheets/scss/tokens';

.wrapper {
  --pb: #{REM(100)};
  padding-bottom: var(--pb);

  // == this is to keep current layout of the table, reference _billing.scss line 1124 ==
  $th-widths: (8%, 9%, 22%, 10%, 30%, 8%, 10%, 10%, 3%);

  @for $idx from 1 through 9 {
    th:nth-child(#{$idx}) {
      width: nth($th-widths, $idx);
    }
  }
  // ================================================================================

  .table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    background-color: $theme-white;

    // reference _base.scss line 82
    thead, tbody {
      background-color: transparent;
    }

    td, th {
      border: none;
      padding: 0.75rem;
    }

    thead {
      tr {
        height: 3.25rem;

        th {
          color: $theme-text-primary;
          @include type-sm-bold;
          text-transform: uppercase;
          text-align: left;
          vertical-align: middle;
        }
      }
    }

    tbody {
      tr {
        height: 64px;
        border-bottom: 1px solid $theme-background-secondary;
        td {
          @include type-lg;
          a {
            color: $theme-accent-primary;
          }
          .dropdown{
            i {
              color: $theme-accent-primary;
              font-size: 18px;
            }
          }
        }
      }
    }

    tbody tr:last-child {
      border-bottom-width: 0;

      td, th {
        border-bottom-width: 0;
      }
    }

    td {
      padding: 0.75rem;

      &:not([align]) {
        text-align: inherit;
      }
    }

    tbody tr td:last-child, thead tr th:last-child {
      padding-right: 0px
    }
  }

  @include mobile{
    --pb: #{REM(50)};
    overflow-x: scroll;
  }
}
