@use "../../../../assets/stylesheets/scss/tokens" as *;

.modalActions {
  text-align: center;
  margin-top: 24px;
  button {
    margin: 0 8px;
  }
}


.confirmPopupBottomContent {
  display: flex;
  justify-content: center;
  margin-top: 16px;
  color: $theme-text-secondary;

  &Tip {
    @include type-lg;
  }
}
