@import 'src/assets/stylesheets/scss/tokens';

.labOrderRow {
  .nameCell {
    width: 30%;

    .orderNameBox {
      display: flex;
      align-items: center;

      >svg {
        flex-shrink: 0;
      }

      >:first-child {
        align-items: center;
        margin-right: 16px;

        &:not(svg) {
          width: 32px;
          height: 32px;
        }
      }

      >div {
        >p:first-child {
          @include type-lg-bold;
        }
      }
    }
  }

  .dateCell {
    width: 16%;

    .dateAndActions {
      display: flex;
      align-items: center;

      >span {
        @include type-sm;
        color: $theme-text-secondary;
        text-align: right;
        flex-shrink: 0;
      }

      >div {
        flex-shrink: 0;
      }
    }
  }

  .tooltip {
    padding: 8px 12px;
    border-radius: 8px;
    p {
      @include type-sm;
    }
    .labName {
      @include type-sm-bold;
      text-align: center;
      text-transform: capitalize;
    }
  }

  .patientRow {

    span {
      color: $theme-notice-bold-warning;
    }
  }
}
