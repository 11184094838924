@import "../../../assets/stylesheets/scss/tokens";
.popup {
  border-radius: 10px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  background-color: $theme-background-primary;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  outline: 0;
  z-index: 10;
  padding: 18px;

  &WithTriangle {
    .healthieDropdownTriangle {
      top: -12px;
      left: 50px;
      margin-top: -3px;
      margin-left: -8px;
      border-top: none;
      border-bottom-color: white !important;
      width: 1px;
      height: 0;
      position: absolute;
      box-sizing: content-box;
      border: 8px solid transparent;
    
    }
    .healthieDropdownTriangle::before {
      top: -9px;
      height: 0;
      width: 1px;
      position: absolute;
      box-sizing: content-box;
      border: 8px solid transparent;
      left: -8px;
      content: "";
      z-index: -1;
      border-width: 8px;
      border-bottom-color: rgba(0,0,0,.25);
    }
  }
}
.popupTitleWrapper {
  position: relative;
  .popupTitle {
    padding-bottom: 20px;
    color: $theme-text-primary;
    border-bottom: 1px solid $theme-border-primary;
    @include type-h4;
  }
}



.closeModal {
  top: 4px;
  right: 4px;
  position: absolute;
  background-color: inherit;
  z-index: 2;
  padding: 1px 6px;
  &.medium {
    &:hover {
      border-radius:100px;
      background-color:rgba(10,10,10,0.3);
    }
    svg > g > g {
      stroke-width: 3;
    }
  }

}

.modalClose:after,
.modalClose:before {
  background-color: $theme-text-primary;
}
