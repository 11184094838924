@import "./src/assets/stylesheets/scss/tokens";

.pill {
  color: $theme-white;
  text-transform: uppercase;
  border-radius: 20px;
  text-align: center;

  &.sm {
    @include type-sm-bold;
    padding: 0.5rem;
  }
  &.lg {
    @include type-lg-bold;
    padding: 0.5rem 1rem;
  }
  &.disabled {
    background: $theme-background-disabled;
  }
  &.alert {
    background: $theme-notice-bold-alert;
  }
  &.success {
    background: $theme-notice-bold-success;
  }
  &.info {
    background: $theme-background-secondary;
    color: $theme-text-primary;
  }
  &.warning {
    background: $theme-notice-bold-warning;
  }
}
