@import "./src/assets/stylesheets/scss/tokens";
@import "./src/assets/stylesheets/scss/utilities/MediaQueries/mediaQueryMixins";

.sidebarContainer {
		width: 100%;
		height: 100%;
		background: $theme-background-primary;
		border-right: 1px solid $theme-border-primary;
		padding-bottom: 2rem;
		.row {
				padding: 0;
				margin: 0 !important;
		}
		@include mobile {
				max-width: unset;
		}
		.h3Title {
				@include type-h4;
				color: $theme-text-primary;
				padding: 1.5rem 1.5rem 0.7rem !important;
		}
		.section {
				justify-content: space-between;
				.sectionHeaderContainer {
						display: flex;
						align-items: center;
						padding: 0.75rem 1.5rem 0.5rem;
						.sectionHeader {
								color: $theme-text-secondary;
							@include type-sm-bold-uppercase;
						}
						svg {
								margin-right: 8px;
						}
				}
		}
}