@import "./src/assets/stylesheets/scss/tokens";

.PolicyFields {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    .desktop-checkbox-container {
        .checkmark {
            top: 5px;
        }
    }

    .sectionHeader {
        padding: 0.5rem 0.75rem;
        background-color: $theme-background-secondary;
        color: $theme-text-primary;
        @include type-sm-bold-uppercase;
    }

    .policyDivider {
        width: 100%;
        border-top: 2px solid $theme-border-primary;
    }
}
