@import "src/assets/stylesheets/scss/tokens";

.container {
  border-radius: 16px;
  background-color: $theme-background-secondary;
  height: 184px;
  padding: 32px 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .planName {
    @include type-h3;
  }
  .planInfo {
    @include type-lg;
  }
}
