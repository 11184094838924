@import "./src/assets/stylesheets/scss/tokens";

.inputField {
  display: flex;
  :global{
    .react-datepicker-wrapper {
      width: 300px;

      .react-datepicker__input-container {
        width: 100%;

        input[type=text] {
            background-color: $theme-background-input;
            border: 1px solid $theme-border-input;
            height: 35px;
            width: 100%;
        }
      }
    }
  }
}