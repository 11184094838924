@import "src/assets/stylesheets/scss/tokens";

.field {
  display: flex;
  align-items: center;
  position: relative;
  float: left;


  &:not(.noBottomMargin) {
    margin-bottom: .75rem;
  }

   &:not(:last-child) {
    margin-right: .75rem;
  }
  
  .labelContainer {
    display: flex;
    cursor: pointer;
    user-select: none;
    padding: 0;
    margin: 0;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    align-items: center;
    position: relative;
    color: $theme-text-primary;
    text-align: left;
    @include type-lg;
    padding-left: 5px;
    align-items: center;
    line-height: 14px;
  }

  [data-error-adapter] {
    position: absolute;
    bottom: -13px;
  }
}

 .icdCheckboxes {
              display: flex;
              flex-flow: row wrap;
              width: 540px;
              
              [data-ff-adapter] {
                margin-right: 0.9rem;
                width: 110px;
                margin-bottom: 0.3rem;
              }
              
              [data-field-with-error]::after {
                top: -2px;
                height: calc(100% + 4px);
              }
            }
