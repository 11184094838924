@import 'src/assets/stylesheets/scss/tokens';

.labOrderConnectModal {
  div > section {
    border-radius: 0px;
  }

  .labOrderConnectLink {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 99px;
    border: 1px solid $theme-border-primary;
    border-radius: 4px;
    margin: 16px;
    @include shadow-sm;

    &.connected {
      pointer-events: none;

      span {
        display: flex;
        align-items: center;

        span {
          color: $theme-notice-bold-success;
          margin-left: 16px;
        }
      }
    }
  }
}

