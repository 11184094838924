@import "src/assets/stylesheets/scss/tokens";

.wrapperForStickyElements {

  .wrapper {
    padding: 30px;
    background-color: $theme-background-primary;
    min-height: calc(100vh - 60px);

    .sectionActions {
      display: flex;
      flex-direction: column;

      .npiContainer {
        margin-bottom: 18px;

        span {
          @include type-lg;

          &:first-child {
            @include type-lg-bold;
            margin-right: 6px;
          }
        }
      }

      .sectionControls {
        display: flex;

        > div {
          display: flex;
          align-items: center;
          cursor: pointer;

          &:first-child {
            margin-right: 25px;
          }

          svg {
            margin-right: 8px;
          }
        }

        span {
          @include type-lg-bold;
          color: $theme-accent-primary;
        }
      }
    }

    .header {
      margin-bottom: 35px;

      span {
        color: $theme-text-primary;
        @include type-h2;
      }
    }

    .form {

      .clientInfo {
        display: flex;
        flex-direction: column;
        max-width: 1000px;

        .genderDobContainer {
          display: flex;
          width: 655px;
          justify-content: space-between;

          input {
            max-width: unset;

            &[name=customMonth] {
              width: 91px;
              margin-right: 12px;
            }

            &[name=customDay] {
              width: 91px;
              margin-right: 12px;
            }

            &[name=customYear] {
              width: 106px;
            }
          }

          .gender {
            width: 312px;
          }
        }

        .addressInput {
          display: flex;
          flex-flow: row wrap;
          width: 1000px;
          justify-content: space-between;

          > div {
            width: 312px;
          }
        }
      }

      .referralInfo {
        display: flex;
        flex-direction: column;

        .group {
          display: flex;
          width: 795px;

          .subgroup {
            display: flex;
            flex-direction: row;

            &.vertical {
              flex-direction: column;
            }

            &.halfWidth {
              width: 397px;
            }

            .subgroupFields {
              display: flex;
              margin-right: 13px;
            }

            .referralDatesAdapter input {

              &:nth-child(2), &:nth-child(3) {
                width: 49px;
                margin-right: 5px;
              }

              &:last-child {
                width: 64px;
                margin-right: 20px;
              }
            }

            .referralSelectAdapter {
              width: 197px;
            }
          }

          &.caseTypeFields .caseType {

            input {
              height: unset;
            }

            .stateSelectAdapter {
              margin-left: 20px
            }
          }

          &.sickDates {
            border-top: 1px solid $theme-border-primary;
            margin-top: 16px;
            padding-top: 37px;
          }

          .groupLabel {
            margin-bottom: 12px;
            @include type-lg-bold;
            display: inline-block;
          }
        }
      }

      .refProviderInfo {
        display: flex;
        flex-direction: column;

        [data-ff-adapter] {
          width: 317px;
        }

        .mainRefProviderFields {

          > div {
            margin-bottom: 0.75rem
          }
        }

        .optionalRefProviderFields {
          display: flex;
          flex-flow: row wrap;

          [data-ff-adapter] {
            margin-right: 24px;
          }
        }
      }

      .serviceFacilityInfo {

        [data-ff-adapter] {
          width: 317px;
        }
      }

      .billProviderInfo {

        [data-ff-adapter] {
          width: 317px;
        }

        .npiRadio {
          display: flex;
          flex-direction: column;
          margin-bottom: 20px;
        }
      }

      .miscInfo {
        display: flex;
        flex-direction: column;
        width: 1008px;

        .miscGroup {
          display: flex;
          justify-content: space-between;
          margin-bottom: 0.75rem;

          [data-ff-adapter] {
            width: 494px;
          }

          .miscSubGroup {
            display: flex;
            flex-direction: column;
          }
        }
      }

      .codesInfo {
        display: flex;
        flex-direction: column;
        
        .bottomRow {
          border-bottom: 1px dashed $theme-border-primary;
          margin-bottom:1.75rem;
          padding-bottom: 0.75rem;
        }

        &.icds {
          .icdIcon {
            text-align: center;
            h5 {
              height: 2rem;
              width: 2rem;
              padding: 0.25rem 0.75rem;
              @include type-lg;
              color: $theme-text-primary;
              border-radius: 0.5rem 0.5rem 0.5rem 0;
              background-color: $theme-background-secondary;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
        
        &.cpts {
          .cptModifiers {
            padding-left: 0.25rem;
            input{
              margin-top: 0.93rem;
            }
          }
          .icdPointers {
            position: relative;
            z-index: 0;
            
            .icdCheckboxes {
              display: flex;
              flex-flow: row wrap;
              width: 540px;
              
              [data-ff-adapter] {
                margin-right: 0.9rem;
                width: 110px;
                margin-bottom: 0.3rem;
              }
              
              [data-field-with-error]::after {
                top: -2px;
                height: calc(100% + 4px);
              }
            }
            
            .icdPointerLabel {
              display: flex;
              
              .icdPointerIcon {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 20px;
                width: 20px;
                border-radius: 5px 5px 5px 0px;
                background-color: $theme-text-primary;
                margin-right: 0.43rem;
                
                span {
                  @include type-sm-bold;
                  color: $theme-text-secondary;
                }
              }
            }
            
            .icdPointerTooltip {
              display: flex;
              flex-direction: column;
              align-items: center;
            }
            
            > p {
              position: absolute;
              bottom: -12px;
            }
          }
          .totalCharge {
            @include type-h4;
          }
          .cptsFooter {
            width: 1010px;
            display: flex;
            justify-content: space-between;
            
          }
        }
      }
    }

    .cms1500Drawer {

      :global {

        .aside-tab-additional-wrapper {
          padding: 0 25px;

          .aside-tab-content__header {
            height: unset;
            border-bottom: 1px solid $theme-border-primary;
            padding: unset;

            .header-text {
              display: flex;
              align-items: center;
            }

            #close-icon {
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }

      .readonlyDrawerFields {
        padding-top: 25px;
        display: flex;
        flex-direction: column;

        .readonlyBody {
          display: flex;
          flex-flow: row wrap;
          border-bottom: 1px solid $theme-border-primary;
          margin-bottom: 15px;

          .readonlyField {
            display: flex;
            flex-direction: column;
            margin-bottom: 15px;
            width: 100%;

            span {

              &:first-child {
                color: $theme-text-secondary;
                @include type-sm-bold;
              }

              &:last-child {
                @include type-lg-bold;
              }
            }

            &.readonlyHalfWidth {
              width: 50%;
            }
          }
        }

        .readonlyFieldsActions {
          display: flex;
          flex-direction: column;

          div {
            display: flex;
            align-items: center;
            cursor: pointer;

            span {
              @include type-lg-bold;
            }

            svg {
              margin-right: 8px;
            }

            &:first-child {
              margin-bottom: 16px;
            
              span {
                color: $theme-accent-primary;
              }
            }

            &:last-child {
              
              svg {
                margin-top: -2px;
              }

              span {
                color: $theme-notice-bold-warning;
              }
            }
          }
        }
      }

      .serviceFaUpdateForm {

        form {
          display: flex;
          flex-flow: row wrap;
          justify-content: space-between;
          margin-top: 10px;

          > div {
            width: 100%;
          }

          .addressInput {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;

            > div {
              width: 48%;
            }
          }

          .serviceFaHalfWidth {
            width: 48%;
          }

          .formControls {
            display: flex;
            align-items: center;
            flex-flow: row wrap;
            justify-content: space-between;
            padding-top: 20px;
            margin-top: 20px;
            border-top: 1px solid $theme-border-primary;

            button {
              width: 48%;
            }

            div {
              display: flex;
              align-items: center;
              justify-content: center;
              margin-top: 20px;
              width: 100%;

              svg {
                margin-top: -1px;
              }

              span {
                cursor: pointer;
                @include type-lg-bold;
                color: $theme-notice-bold-warning;
                margin-left: 10px;
              }
            }
          }
        }
      }

      .billProviderUpdateForm {
        display: flex;
        flex-direction: column;

        form {
          display: flex;
          flex-flow: row wrap;
          justify-content: space-between;
          padding-top: 10px;

          [data-input-for='name'],
          [data-input-for='phone_number'],
          [data-input-for='npi'],
          [data-input-for='primary'] {
            width: 100%;
          }

          .locationInput {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;

            [data-input-for='location.line1'],
            [data-input-for='location.line2'],
            [data-input-for='location.city'],
            [data-input-for='location.state'] {
              width: 48%;
            }

            [data-input-for='location.zip'] {
              width: 30%;
            }

            [data-input-for='location.country'] {
              width: 66%;
            }
          }

          [data-input-for='other_id_qual'],
          [data-input-for='other_id'],
          [data-input-for='tax_id_type'],
          [data-input-for='tax_id'] {
            width: 48%;
          }

          [data-for='primary__tooltip'] {
            margin-left: 5px;
          }

          [data-input-for='primary'] {

            .react-tooltip {
              width: 300px;
            }
          }
        }

        .controls {
          display: flex;
          justify-content: space-between;
          align-items: center;
          justify-content: center;
          width: 100%;
          margin-top: 15px;
          margin-bottom: 20px;
          padding-top: 20px;
          border-top: 1px solid $theme-border-primary;

          > div, button {
            height: 38px;
            margin-bottom: unset;
          }

          > button {
            width: 48%;
            margin-left: 16px;
          }

          > div {
            width: 48%;

            button {
              width: 100%;
            }
          }
        }

        .deleteAction {
          display: flex;
          align-items: center;
          justify-content: center;

          span {
            cursor: pointer;
            @include type-lg-bold;
            color: $theme-notice-bold-warning;
            margin-left: 7px;
          }
        }
      }

      .refProviderForm {
        display: flex;
        padding-bottom: 40px;
        padding-top: 10px;

        // these styles should be removed when we update EditReferringPhysicianForm to have its own scss module
        :global {

          [data-input-for="other_id_qualifier"] {
            width: 49%;
            margin-top: 14px;

            > div, > div > div {
              min-height: 31.5px;
              height: 31.5px;
            }
          }
        }
      }
    }
  }

  [data-field-with-error] {
    position: relative;

    > *:not([data-label-adapter], [data-error-adapter]) {
      position: relative;
      z-index: 1;
    }
    

    > label {
      position: relative;
      z-index: 2;
    }

    &:after {
      content: '';
      position: absolute;
      display: flex;
      width: calc(100% + 8px);
      height: 100%;
      top: 0;
      margin-left: -4px;
      border-radius: 2px;
      background-color: $theme-notice-soft-warning;
    }
  }
}
