@import "./src/assets/stylesheets/scss/tokens";

.sectionItem {
		display: flex;
		color: $theme-text-primary;
		align-items: center;
		text-transform: capitalize;
		padding: 0.5rem 1.5rem !important;
		&:last-child{
				padding-bottom: 0.75rem!important;
		}
		span {
				@include type-lg;
		}
		.noSectionHeader {
				justify-content: flex-start;
		}
		svg {
				margin-right: 8px;
		}
		&:hover, &:focus {
				background: $theme-background-secondary;
		}
}

.isActive{
		background: $theme-background-primary;
		color: $theme-text-primary;
		span {
				@include type-lg-bold;
		}
}