@import 'assets/stylesheets/scss/tokens';
@import '../Button.module.scss';

button.secondary, a.secondary {
  @extend .healthieButton;
  
  @include iconStyles;
}

.secondary {
  background-color: transparent;

  &.default {
    color: $theme-accent-primary;
    border: 1px solid $theme-accent-primary;
  }

  &.danger {
    color: $theme-notice-bold-warning;
    border: 1px solid $theme-notice-bold-warning;
  }

  &:hover {
      background-color: $theme-background-disabled;
      &.default {
        color: $theme-accent-primary-hover;
      }
      &.danger {
        color: $theme-notice-bold-warning-hover;
      }
  }

  &:active {
      &.default {
        color: $theme-accent-primary-hover;
        border: 1px solid $theme-accent-primary-hover;
        background-color: $theme-background-disabled;
      }
      &.danger {
        color: $theme-notice-bold-warning-hover;
        border: 1px solid $theme-notice-bold-warning-hover;
        background-color: $theme-background-disabled;
      }
    }
}

.buttonWithIcon {
  &.secondary {
    &:disabled {
      &:hover,
      &:active,
      &:focus-visible {
        cursor: default;
      }
    }
  }
}

.buttonWithText {
  &:disabled,
  &.loading {
    &.secondary {
      &.default,
      &.danger {
        cursor: default;
      }
    }
  }
}

.buttonWithTextAndIcon {
  @extend .buttonWithText;
}
