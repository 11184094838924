@import "./src/assets/stylesheets/scss/tokens";

.sectionHeaderRow {
  background: $theme-background-primary;
  border-bottom: 2px solid $theme-border-primary;
  .title {
    @include type-h4;
    color: $theme-text-primary;
  }
  .action {
    text-align: right;
  }
  &.isDisabled {
    border-bottom: 2px solid $theme-border-primary;
    .title {
      color: $theme-text-secondary;
    }
  }
  .date {
    color: $theme-text-secondary;
    @include type-sm-bold-uppercase;
  }
}

.categoryType {
  min-height: 2.5rem;
  background: $theme-background-secondary;
  color: $theme-text-secondary;
  @include type-sm-bold;
  svg {
    margin: 0 9px;
  }
  img {
    margin: 0 9px;
    width: 12px;
    height: 12px;
  }
  h5 {
    @include type-sm-bold-uppercase;
  }
}

.topDivider {
  border-top: 1px solid $theme-border-primary;
  margin-bottom: 1rem;
  padding-top: 5;
}

.topPadding {
  padding-top: 0.75rem !important;
}

.topMargin {
  margin-top: 0.75rem !important;
}

.noBottomMargin {
  margin-bottom: 0 !important;
}

.allergyRow {
  border-bottom: 1px dashed $theme-border-primary;
  &:last-child {
    border-bottom: none;
    padding-bottom: 0.75rem
  }
  .name {
    @include type-lg
  }
  .reaction {
    @include type-sm
  }
}

.noKnownAllergies {
  padding: 1rem 1rem 2rem
}
