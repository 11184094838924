
@import "src/assets/stylesheets/scss/tokens";

.beforeApptSectionContainer {
  margin-bottom: 1rem;

  .actionsListItem:not(:last-child) > div {
    border-bottom: 1px solid $theme-border-primary;
  }

  .actionsListItem {
    .actionListItemName {
      display: flex;
      flex-direction: column;

    }
    .actionListItemName p:first-child {
      @include type-sm-bold-uppercase;
      color: $theme-text-primary;
      text-transform: uppercase;
      width: 100%;
    }

    .actionListItemName p:not(:first-child) {
      @include type-sm;
      color: $theme-text-secondary;
      margin-right: 0.125rem;
    }

    .actionListItemName p:nth-child(2) {
      @include type-sm-bold;
    }
  }
}
