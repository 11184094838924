@import 'src/assets/stylesheets/scss/tokens';

.orderNameAndStatus {
  .testName {
    @include type-lg-bold;
  }

  .statusBox {
    display: flex;
    align-items: center;
    margin-top: 4px;

    >p {
      @include type-lg;
      margin-right: 4px;
    }

    .viewAction {
      display: inline-block;
  
      >div {
        width: fit-content;
        height: fit-content;
        padding: 0;
  
        >button {
          margin-left: none;
        }
      }
    }
  }

  .pending {
    color: $theme-notice-bold-alert;
  }

  .error {
    color: $theme-notice-bold-warning
  }

  .requires_client_action {
    color: $theme-notice-bold-warning
  }
}
