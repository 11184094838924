@import 'src/assets/stylesheets/scss/_tokens.scss';

.healthieCollapsibleSection {
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: $theme-background-primary;
  border: 1px solid $theme-border-primary;
  margin-bottom: 1rem;

  &.hideBorder {
    border: none;
  }

  &.transparentBordered {
    background: none;
  }

  &:not(.multiCollapsible, .hideBorder) {
    border: 1px solid $theme-border-primary;
  }

  &.multiCollapsible.clipContent:not(:first-child) {
    clip-path: inset(0 -4px -6px -4px);
  }

  .sectionHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    &:not(.noPadding) {
      padding: 0 20px;
    }
    cursor: pointer;

    .headerName {
      @include type-lg-bold;
      color: $theme-text-primary;
      pointer-events: none;
    }

    .label {
      margin-right: auto;
    }

    .actionsContainer {
      display: flex;
      align-items: center;
      .marginRight {
        margin-right: 1rem;
      }
    }
  }

  .sectionBody {
    &:not(.noPadding) {
      padding: 1rem;
    }
    height: 100%;
  }

  &.isCollapsed {
    .sectionBody {
      display: none;
    }
  }

  &.noBottomMargin {
    margin-bottom: 0px;
  }

  &.fullHeight {
    height: 100%
  }
}

.fullHeight {
  height: 100%
}
