@import "./src/assets/stylesheets/scss/tokens";

.datePicker  {
  label {
    margin: 8px 0px 5px 0px;
    display:block;
  }
  input {
    @include type-lg;
    min-height: 30px;
    border-radius: 3px;
    background-color: $theme-background-input;
    border: 1px solid $theme-border-input;
    box-shadow: none;
    color: $theme-text-primary;
    box-sizing: border-box;
    padding: calc(.375em - 1px) calc(.625em - 1px);

    &::placeholder {
      color: $theme-text-secondary;
    }
    &:hover {
      background-color: $theme-background-input;
      box-shadow: none;
      border: 1px solid $theme-accent-primary;
      color: $theme-text-primary;
      box-sizing: border-box;
    }
    &[disabled]{
      background-color: $theme-background-disabled;
      box-shadow: none;
      color: $theme-text-secondary;
    }
  }
}
.disabledInputTooltip {
  width: 300px;
}