@import "assets/stylesheets/scss/tokens";

.dayInput {
  height: 33px;
  width: 29px;
  padding: 0.25rem;
  border-radius: 3px;
  border: 1px solid $theme-border-input;
  text-align: center;
  margin: 0 0.25rem;
  &:disabled {
    outline: 1px solid $theme-border-input;
  }
}