:root {
  // Theme: Healthie Classic Light
  --theme-background-primary: #FFFFFF;
  --theme-background-secondary: #EEEEEE;
  --theme-background-sidebar: #16284A;
  --theme-background-hover: #00000015;
  --theme-background-disabled: #CACBCE;
  --theme-background-input: #FFFFFF;
  --theme-text-primary: #000000;
  --theme-text-secondary: #828282;
  --theme-text-sidebar: #FFFFFF;
  --theme-text-sidebar-active: #2E74C7;
  --theme-text-sidebar-tab-bar: #2E74C7;
  --theme-accent-primary: #2E74C7;
  --theme-accent-primary-hover: #2F4F75;
  --theme-border-primary: #D8D9DC;
  --theme-border-input: #8A8D96;
  --theme-notice-bold-alert: #FFAF1E;
  --theme-notice-bold-warning: #EE333C;
  --theme-notice-bold-warning-hover: #B20F17;
  --theme-notice-bold-success: #7ABC32;
  --theme-notice-soft-alert: #FCEAC6;
  --theme-notice-soft-warning: #F5CFCF;
  --theme-notice-soft-success: #DDEAD7;
  --theme-white: #FFFFFF;
}

// Theme Tokens
$theme-background-primary: var(--theme-background-primary);
$theme-background-secondary: var(--theme-background-secondary);
$theme-background-sidebar: var(--theme-background-sidebar);
$theme-background-hover: var(--theme-background-hover);
$theme-background-disabled: var(--theme-background-disabled);
$theme-background-input: var(--theme-background-input);
$theme-text-primary: var(--theme-text-primary);
$theme-text-secondary: var(--theme-text-secondary);
$theme-text-sidebar: var(--theme-text-sidebar);
$theme-text-sidebar-active: var(--theme-text-sidebar-active);
$theme-text-sidebar-tab-bar: var(--theme-text-sidebar-tab-bar);
$theme-accent-primary: var(--theme-accent-primary);
$theme-accent-primary-hover: var(--theme-accent-primary-hover);
$theme-border-primary: var(--theme-border-primary);
$theme-border-input: var(--theme-border-input);
$theme-notice-bold-alert: var(--theme-notice-bold-alert);
$theme-notice-bold-warning: var(--theme-notice-bold-warning);
$theme-notice-bold-warning-hover: var(--theme-notice-bold-warning-hover);
$theme-notice-bold-success: var(--theme-notice-bold-success);
$theme-notice-soft-alert: var(--theme-notice-soft-alert);
$theme-notice-soft-warning: var(--theme-notice-soft-warning);
$theme-notice-soft-success: var(--theme-notice-soft-success);
$theme-white: var(--theme-white);
$theme-accent-highlight: color-mix(in srgb, var(--theme-accent-primary), transparent 75%);

// ***** Typography Tokens *****
@mixin type-h1 {
  font-family: "Inter", Helvetica, "Arial", sans-serif;
  font-weight: 600;
  font-size: 3.5rem;
  line-height: 4.5rem;
}
@mixin type-h2 {
  font-family: "Inter", Helvetica, "Arial", sans-serif;
  font-weight: 600;
  font-size: 2.5rem;
  line-height: 3rem;
}
@mixin type-h3 {
  font-family: "Inter", Helvetica, "Arial", sans-serif;
  font-weight: 600;
  font-size: 2rem;
  line-height: 3rem;
}
@mixin type-h4 {
  font-family: "Inter", Helvetica, "Arial", sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2.5rem;
}

@mixin type-lg {
  font-family: "Inter", Helvetica, "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
}
@mixin type-sm {
  font-family: "Inter", Helvetica, "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
}
@mixin type-lg-bold {
  font-family: "Inter", Helvetica, "Arial", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
}
@mixin type-sm-bold {
  font-family: "Inter", Helvetica, "Arial", sans-serif;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1rem;
}

@mixin type-sm-bold-uppercase {
  font-family: "Inter", Helvetica, "Arial", sans-serif;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1rem;
  text-transform: uppercase;
}

// *** global Typography classes ***//
.type-h1{
  @include type-h1;
}
.type-h2{
  @include type-h2;
}
.type-h3{
  @include type-h3;
}
.type-h4{
  @include type-h4;
}
.type-sm{
  @include type-sm;
}
.type-sm-bold{
  @include type-sm-bold;
}
.type-lg{
  @include type-lg;
}
.type-lg-bold{
  @include type-lg-bold;
}


// ***** Effects Tokens *****
@mixin shadow-sm {
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.1), 0 1px 8px rgba(0, 0, 0, 0.15);
}
@mixin shadow-md {
  box-shadow: 0 8px 17px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.1);
}
@mixin shadow-lg {
  box-shadow: 0 16px 38px rgba(0, 0, 0, 0.14), 0 8px 15px rgba(0, 0, 0, 0.1);
}

@function REM($px, $base: 16) {
  @return #{calc($px / $base)}rem;
}
