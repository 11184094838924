@import 'src/assets/stylesheets/scss/tokens';

.newLabOrderModal {
  div > section {
    border-radius: 0px;
  }

  .newLabOrder {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .newOrderTrigger {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 99px;
      border: 1px solid $theme-border-primary;
      border-radius: 4px;
      @include shadow-sm;
      cursor: pointer;
      &.disabled {
        background-color: $theme-background-disabled;
        cursor:not-allowed
      }
    }
  }
}
