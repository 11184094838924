.datePicker {
  :global {
    .react-datepicker-wrapper  {
      width: 100%;
      input {
        width: 100%;
      }
    }
  }
}

.reviewerWrapper {
  width: 100%;
  input {
    width: 100%;
  }
}

.submit {
  padding-top: 1rem;
}

.selectedTests {
  li {
    margin-left: 2rem;
  }
}
