@import "assets/stylesheets/scss/tokens";

.wrapper {
  display: flex;
  align-items: center;
}

.sectionLabel {
  @include type-sm;
  padding: 1rem 0 0.5rem 0;
}

.timeInput {
  width: 106px;
  height: 36px;
  margin: 0.5rem;
  @include type-lg;
}

:global {
  li.react-datepicker__time-list-item--disabled {
    cursor: default;
  }
  .react-datepicker-popper {
    min-width: 100px;
  }
}
