@import 'src/assets/stylesheets/scss/_tokens.scss';

.container {
  
  .header {
    @include type-sm;
  }

  .chartNotes {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .line {
      display: flex;
      align-items: center;
      width: 100%;
      max-width: 100%;
      margin-bottom: .5rem;

      button {
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-height: unset;
        margin-left: 0;
        padding: 0;
        text-align: left;
      }
    }
  }
}

.locked {
  display: flex;
  background-color: $theme-background-disabled;
  border-radius: 0.7rem;
  align-items: center;
  min-width: 5.938rem;
  height: 1.5rem;
  justify-content: center;
  gap: .25rem;
  margin-left: .625rem;

  span {
    @include type-sm-bold;
    color: $theme-background-primary
  }
}
